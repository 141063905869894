import logo from "../images/logo.png";

function Welcome() {
  return (
    <div className="App">
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <img src={logo} alt="JT Field Logo" />
          <h2 className="text-4xl font-bold text-jt-blue">Coming Soon</h2>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
